// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  clear: both;
  overflow: hidden;
}
.incoming_msg_img {
	display: inline-block;
	width: 6%;
}
.received_msg {
	display: inline-block;
	padding: 0 0 0 10px;
	vertical-align: top;
	width: 92%;
}
.received_withd_msg {
	p {
		background: #ebebeb none repeat scroll 0 0;
		border-radius: 3px;
		color: #646464;
		font-size: 14px;
		margin: 0;
		padding: 5px 10px 5px 12px;
		width: 100%;
	}
	width: 57%;
}
.time_date {
	color: #747474;
	display: block;
	font-size: 12px;
	margin: 8px 0 0;
}
.chat_name {
    color: #000;
    font-weight: 800;
	display: block;
	font-size: 12px;
	margin: 8px 0 0;
}
.mesgs {
	float: left;
	padding: 30px 15px 0 25px;
	width: 100%;
}
.sent_msg {
	p {
		background: #05728f none repeat scroll 0 0;
		border-radius: 3px;
		font-size: 14px;
		margin: 0;
		color: #fff;
		padding: 5px 10px 5px 12px;
		width: 100%;
	}
	float: right;
	width: 46%;
}
.outgoing_msg {
	overflow: hidden;
	margin: 26px 0 26px;
}
.input_msg_write {
	input {
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
		border: medium none;
		color: #4c4c4c;
		font-size: 15px;
		min-height: 48px;
		width: 100%;
	}
}
.type_msg {
	border-top: 1px solid #c4c4c4;
	position: relative;
}
.msg_send_btn {
	background: #05728f none repeat scroll 0 0;
	border: medium none;
	border-radius: 50%;
	color: #fff;
	cursor: pointer;
	font-size: 17px;
	height: 33px;
	position: absolute;
	right: 0;
	top: 11px;
	width: 33px;
}
.messaging {
	padding: 0 0 50px 0;
}
.msg_history {
	height: 516px;
	overflow-y: auto;
}
